<template>
  <div class="value">
    <div
      class="value__container col-11 col-sm-10 m-auto"
      data-aos="fade-up"
      data-aos-duration="500"
      data-aos-offset="300"
    >
      <div class="value__content">
        <h2>{{ title }}</h2>
        <p>{{ sub }}</p>
      </div>
      <div class="value__block d-none d-sm-flex">
        <img v-lazy="nhanhoaImg" class="img-nhanhoa" alt="nhanhoa" />
        <img v-lazy="data.people.img" class="img-nhan" alt="people" />
        <img v-lazy="data.piece.img" class="img-hoa" alt="piece" />
        <div class="col-12 col-xl-6 value__block__item">
          <div class="left">
            <div class="value__block__item--container">
              <h3>{{ data.people.title }}</h3>
              <p>{{ data.people.subject }}</p>
              <p class="value__block__item--slogan">{{ data.people.slogan }}</p>
              <p>{{ data.people.content }}</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-xl-6 value__block__item item__right">
          <div class="right">
            <div class="value__block__item--container">
              <h3>{{ data.piece.title }}</h3>
              <p>{{ data.piece.subject }}</p>
              <p>{{ data.piece.content }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="value__block d-flex d-sm-none">
        <div class="col-12 col-lg-6 value__block__item">
          <div class="left">
            <div class="value__block__item--container--mobile">
              <h3>{{ data.people.title }}</h3>
              <div class="d-flex align-items-center">
                <p>{{ data.people.subject }}</p>
                <img v-lazy="data.people.img" width="100px" alt="people" />
              </div>
              <p class="value__block__item--slogan">{{ data.people.slogan }}</p>
              <p v-if="flagReadMorePeople">{{ data.people.content }}</p>
              <span
                @click="flagReadMorePeople = !flagReadMorePeople"
                style="color: var(--orange); font-size: 16px; cursor: pointer"
                >{{
                  !flagReadMorePeople ? data.people.show : data.people.hide
                }}</span
              >
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 value__block__item item__right">
          <div class="right">
            <div class="value__block__item--container--mobile">
              <h3>{{ data.piece.title }}</h3>
              <div class="d-flex align-items-center">
                <p>{{ data.piece.subject }}</p>
                <img v-lazy="data.piece.img" width="120px" alt="piece" />
              </div>
              <p v-if="flagReadMorePiece">{{ data.piece.content }}</p>
              <span
                @click="flagReadMorePiece = !flagReadMorePiece"
                style="color: var(--orange); font-size: 16px; cursor: pointer"
                >{{
                  !flagReadMorePiece ? data.piece.show : data.piece.hide
                }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Value',
  data() {
    return {
      nhanhoaImg: '/images/icons/aboutus/nhanhoa.svg',
      title: this.$t('about_us').value.title,
      sub: this.$t('about_us').value.sub,
      flagReadMorePeople: false,
      flagReadMorePiece: false,
      data: {
        people: {
          img: '/images/icons/aboutus/penguin_nhan.svg',
          title: this.$t('about_us').value.cards.people.title,
          slogan: this.$t('about_us').value.cards.people.slogan,
          subject: this.$t('about_us').value.cards.people.subject,
          content: this.$t('about_us').value.cards.people.content,
          show: 'Đọc thêm',
          hide: 'Ẩn bớt'
        },
        piece: {
          img: '/images/icons/aboutus/penguin_hoa.svg',
          title: this.$t('about_us').value.cards.piece.title,
          subject: this.$t('about_us').value.cards.piece.subject,
          content: this.$t('about_us').value.cards.piece.content,
          show: 'Đọc thêm',
          hide: 'Ẩn bớt'
        }
      }
    };
  },
  watch: {
    lang(val) {
      this.$i18n.locale = val;
      this.title = this.$t('about_us').value.title;
      this.sub = this.$t('about_us').value.sub;
      this.data = {
        people: {
          img: '/images/icons/aboutus/penguin_nhan.svg',
          title: this.$t('about_us').value.cards.people.title,
          slogan: this.$t('about_us').value.cards.people.slogan,
          subject: this.$t('about_us').value.cards.people.subject,
          content: this.$t('about_us').value.cards.people.content,
          show: this.$t('button').show,
          hide: this.$t('button').hide
        },
        piece: {
          img: '/images/icons/aboutus/penguin_hoa.svg',
          title: this.$t('about_us').value.cards.piece.title,
          subject: this.$t('about_us').value.cards.piece.subject,
          content: this.$t('about_us').value.cards.piece.content,
          show: this.$t('button').show,
          hide: this.$t('button').hide
        }
      };
    }
  },
  computed: {
    ...mapState({
      lang: (state) => state.langs.lang
    })
  }
};
</script>

<style lang="scss" scroped>
.value {
  width: 100%;
  padding: 50px 0;
  .value__container {
    margin: auto;
    text-align: center;
  }
  .value__content {
    text-align: center;
    max-width: 752px;
    margin: 20px auto 60px;
    h2 {
      position: relative;
      font-weight: 700;
      font-size: 40px;
      color: var(--blue-text);
      margin-bottom: 16px;
      z-index: 0;
    }
    p {
      color: var(--grey-text);
      font-size: 18px;
      margin: 0;
    }
  }
  .value__block {
    font-size: 18px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .img-nhanhoa {
      width: 20%;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }
    .img-nhan {
      height: auto;
      position: absolute;
      bottom: -10%;
      left: 20%;
      z-index: 1;
      opacity: 0.4;
      mix-blend-mode: multiply;
    }
    .img-hoa {
      height: auto;
      position: absolute;
      bottom: -10%;
      right: 20%;
      z-index: 1;
      opacity: 0.4;
      mix-blend-mode: multiply;
    }
  }
  .value__block__item {
    height: 650px;
    padding: 40px;
    display: flex;
    position: relative;
    background: var(--orange);
    border-radius: 20px 0 0 20px;
    clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%);
    margin-left: -5%;
    &.item__right {
      background: var(--blue-text);
      clip-path: polygon(25% 0, 100% 0%, 100% 100%, 0% 100%);
      border-radius: 0 20px 20px 0;
      margin-right: -5%;
    }

    .value__block__item--container {
      width: 70%;
      text-align: left;
      img {
        position: absolute;
        width: 200px;
        height: auto;
        bottom: -100px;
        left: 50%;
      }
      h3 {
        font-size: 32px;
        font-weight: 800;
        color: #002a9c;
        text-align: center;
      }
      p {
        white-space: pre-line;
      }
      .value__block__item--slogan {
        padding: 20px;
        border: 1px dashed #ff6e26;
        border-radius: 10px;
        color: #2353b4;
        font-weight: 600;
        width: auto;
      }
    }

    .value__block__item--container--mobile {
      text-align: left;
      h3 {
        font-size: 32px;
        font-weight: 800;
        color: #002a9c;
        text-align: center;
      }
      p {
        white-space: pre-line;
      }
      .value__block__item--slogan {
        padding: 20px;
        border: 1px dashed #ff6e26;
        border-radius: 10px;
        color: #2353b4;
        font-weight: 600;
        width: auto;
      }
    }

    .left {
      width: 99%;
      height: 99%;
      position: absolute;
      top: 0.5%; /* equal to border thickness */
      left: 0.5%; /* equal to border thickness */
      justify-content: flex-start;
      display: flex;
      border-radius: 20px 0 0 20px;
      background: #fff1ea;
      clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%);
      padding: 30px;
    }
    .right {
      width: 99%;
      height: 99%;
      position: absolute;
      top: 0.5%; /* equal to border thickness */
      right: 0.5%; /* equal to border thickness */
      display: flex;
      justify-content: flex-end;
      border-radius: 0 20px 20px 0;
      background: #e9f5ff;
      clip-path: polygon(25% 0, 100% 0%, 100% 100%, 0% 100%);
      padding: 30px;
    }
  }
  @media (max-width: 1240px) {
    padding: 50px 0;
    .value__content {
      width: 80%;
    }
    .value__block {
      flex-direction: column;
      gap: 30px;
      .img-nhanhoa,
      .img-nhan,
      .img-hoa {
        display: none;
      }
    }
    .value__block__item,
    .value__block__item.item__right {
      clip-path: unset !important;
      margin: 0 !important;
      border-radius: 20px !important;
      background: transparent;
      padding: 0;
      height: auto;

      .left,
      .right {
        border-radius: 20px !important;
        clip-path: unset !important;
        position: unset;
        height: auto;
      }
      .left {
        border: 2px solid var(--orange);
      }
      .right {
        border: 2px solid var(--blue-text);
      }
    }
    .value__block__item .value__block__item--container {
      width: 100%;
    }
  }
  @media (max-width: 1024px) {
    padding: 50px 0;
    .value__content {
      width: 80%;
    }
    .value__block {
      flex-direction: column;
      gap: 30px;
      .img-nhanhoa,
      .img-nhan,
      .img-hoa {
        display: none;
      }
    }
    .value__block__item,
    .value__block__item.item__right {
      clip-path: unset !important;
      margin: 0 !important;
      border-radius: 20px !important;
      background: transparent;
      padding: 0;
      height: auto;

      .left,
      .right {
        border-radius: 20px !important;
        clip-path: unset !important;
        position: unset;
        height: auto;
      }
      .left {
        border: 2px solid var(--orange);
      }
      .right {
        border: 2px solid var(--blue-text);
      }
    }
    .value__block__item .value__block__item--container {
      width: 100%;
    }
  }
  @media (max-width: 768px) {
    .value__content {
      h2 {
        font-size: 32px;
      }
      width: 100%;
    }
    .value__block__item .left,
    .value__block__item.item__right .right {
      padding: 16px;
    }
  }
}
</style>
